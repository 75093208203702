import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import ConsultHistoryCard from '../components/ConsultHistoryCard';
import Header from '../components/Header';
import { getConsultationHistory } from '../services/apiServices';
import { AnalyticsContext } from '../context/Analytics';
import { AuthContext } from '../context/Auth';

const limit = 50;

function ConsultHistory() {
    const [page, setpage] = useState(1);
    const [history, sethistory] = useState([]);
    const [loading, setloading] = useState(false);
    const { gtmPageViewEvent } = useContext(AnalyticsContext) || { gtmPageViewEvent: () => { } }
    const { user, fetchUser } = useContext(AuthContext) || {
      user: {},
      fetchUser: () => { }
    }
    
    useEffect(() => {
        gtmPageViewEvent(window.location.pathname, window.location.host, user.meesho_userId);
    }, [])

    useEffect(() => {
        const fn = async () => {
            setloading(true);
            let res = await getConsultationHistory(page, limit);
            if (res.statusCode === 200) {
                sethistory([...res.payLoad.consultations])
            }
            setloading(false);
        }
        fn();
    }, [])

    return (
        <div className="flex flex-col h-screen worksans">
            <Header />
            <main className="flex-1 overflow-y-auto p-4 grid md:grid-cols-2 lg:grid-cols-3">
                {history.map((v, i) => {
                    return <ConsultHistoryCard key={i} data={v} />
                })}
            </main>
                {history.length==0 && !loading && <div className="h-screen w-full flex items-center justify-center flex flex-col">
                    <h6 className="text-black">Consultations not found.</h6>
                    <button
                      className="rounded px-4 py-1 border text-sm mt-4 transform transition duration-300 hover:scale-[1.02] hover:-translate-y-1 text-white tracking-wide worksans text-base md:text-lg but"
                      onClick={() => {
                        navigate('/consultAstro', {
                            replace: true,
                        });
                      }}
                    >
                      Consult Now
                    </button>
                    </div>}

        </div>
    )
}

export default ConsultHistory

